import logger from '@/utils/logger/logger'

const decodeHref = (encodedHref: string): string => {
  const a = document.createElement('a')
  a.setAttribute('href', encodedHref)
  return a.getAttribute('href') || ''
}

const hexToDecimal = (hexString: string, startIndex: number): number => {
  const hex = hexString.substring(startIndex, startIndex + 2)
  return parseInt(hex, 16)
}

const decodeEmail = (encodedString: string, startIndex: number): string => {
  let decoded = ''
  const key = hexToDecimal(encodedString, startIndex)
  for (let i = startIndex + 2; i < encodedString.length; i += 2) {
    // eslint-disable-next-line no-bitwise
    const charCode = hexToDecimal(encodedString, i) ^ key
    decoded += String.fromCharCode(charCode)
  }
  try {
    decoded = decodeURIComponent(escape(decoded))
  } catch (error) {
    logger.error('Error decoding email:', error)
  }
  return decodeHref(decoded)
}

const processLinks = (context: Document | HTMLElement): void => {
  const emailProtectionPrefix = '/cdn-cgi/l/email-protection#'
  const links = context.querySelectorAll<HTMLAnchorElement>('a')
  for (let i = 0; i < links.length; i += 1) {
    try {
      const link = links[i]
      const index = link.href.indexOf(emailProtectionPrefix)
      if (index > -1) {
        link.href = `mailto:${decodeEmail(link.href, index + emailProtectionPrefix.length)}`
      }
    } catch (error) {
      logger.error('Error processing link:', error)
    }
  }
}

const processEmailElements = (context: Document | HTMLElement): void => {
  const emailSelector = '.__cf_email__'
  const emailDataAttribute = 'data-cfemail'
  const emailElements = context.querySelectorAll<HTMLElement>(emailSelector)
  for (let i = 0; i < emailElements.length; i += 1) {
    try {
      const emailElement = emailElements[i]
      const parent = emailElement.parentNode
      const encodedEmail = emailElement.getAttribute(emailDataAttribute)
      if (encodedEmail && parent) {
        const decodedEmail = decodeEmail(encodedEmail, 0)
        const textNode = document.createTextNode(decodedEmail)
        parent.replaceChild(textNode, emailElement)
      }
    } catch (error) {
      logger.error('Error processing email element:', error)
    }
  }
}

const processTemplates = (
  context: Document | HTMLElement,
  processFunc: (context: Document | HTMLElement) => void
): void => {
  const templates = context.querySelectorAll<HTMLTemplateElement>('template')
  for (let i = 0; i < templates.length; i += 1) {
    try {
      if (templates[i].content) {
        processFunc(templates[i].content as unknown as HTMLElement)
      }
    } catch (error) {
      logger.error('Error processing template:', error)
    }
  }
}

export const processContent = (context: Document | HTMLElement): void => {
  try {
    processLinks(context)
    processEmailElements(context)
    processTemplates(context, processContent)
  } catch (error) {
    logger.error('Error processing content:', error)
  }
}
